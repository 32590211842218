.about {
	margin: 100px 0;
	&__df {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 40px;
		@media (max-width: 768px) {
			flex-direction: column;
		}
		&-reverse {
			@media (max-width: 768px) {
				flex-direction: column-reverse;
			}
		}
	}
	&__title {
		text-align: left !important;
		text-transform: uppercase;
		margin-bottom: 40px;
		&-orange {
			color: #f48020;
		}
	}
	&__text {
		font-size: 20px;
		color: #fff;
		max-width: 670px;
		margin-bottom: 20px;
		&-small {
			font-size: 16px;
		}
	}
	&__link {
		display: inline-block;
		padding: 10px 35px;
		background: #f48020;
		border-radius: 5px;
		color: #fff;
		transition: 0.3s ease;
	}
}
