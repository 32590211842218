.about_us {
	margin-bottom: 100px;
	&__content{
		display: flex;
		align-items: center;
		gap: 20px;
		@media (max-width: 762px) {
			flex-direction: column;
		}
	}
	&__info{
		flex: 2 1;
	}
	&__image-block{
		flex: 1 1;
		max-width: 500px;
	}
	&__title {
		text-transform: uppercase;
		margin-bottom: 40px;
		white-space: pre-wrap;
		text-align: left !important;
		&_yellow {
			color: #f48020;
		}
	}
	&__link {
		font-weight: 400;
		font-size: 14px;
		text-transform: uppercase;
		color: #fff;
		display: inline-flex;
		align-items: center;
		gap: 5px;
		border-bottom: 1px solid #f48020;
		&-block {
			display: flex;
			align-items: center;
			// justify-content: center;
		}
		&-icon {
			path {
				fill: #fff;
				transition: 0.3s ease-in-out;
			}
		}
		&:hover > .about_us__link-icon {
			path {
				fill: #f48020;
			}
		}
	}
	&__block {
		// max-width: 900px;
		// margin: 0 auto;
		// @media (max-width: 800px) {
		// 	max-width: 445px;
		// }
	}
	&__desc {
		text-align: left;
		font-weight: 400;
		font-size: 16px;
		line-height: 18px;
		color: #fff;
		margin-bottom: 10px;
	}
}
