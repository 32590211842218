.hero {
	margin: 0 0 100px 0;

	&__video {
		max-width: 1500px;
		height: auto;
		&-block {
			max-width: 1500px;
			margin: 0 auto;
			position: relative;
			display: flex;
			justify-content: center;
			@media (max-width: 768px) {
				max-width: 1000px;
			}
		}
		@media (max-width: 500px) {
			max-width: 1000px;
		}
	}

	&__info {
		margin-top: -140px;
		position: relative;
		min-height: 200px;
		width: 100%;
		padding: 0 15px;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(0deg, #111a23, #111a23),
				linear-gradient(180deg, #111a2300, #111a23);
			content: ' ';
			filter: blur(25px);
			height: 100%;
			position: absolute;
			width: 100%;
		}
		@media (max-width: 500px) {
			margin-top: -100px;
		}
	}
	&__title {
		position: relative;
		font-family: 'Exo 2';
		text-align: center;
		color: #fff;
		font-size: 36px;
		font-weight: bold;
		margin-bottom: 20px;
		@media (min-width: 768px) {
			font-size: 44px;
		}
	}
	&__text {
		position: relative;
		font-size: 16px;
		color: #fff;
		max-width: 700px;
		margin: 0 auto;
		text-align: center;
	}
	&__presentation {
		&_block {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 15px;
		}
		display: inline-block;
		margin: 0 auto;
		padding: 7px 25px;
		background-color: #f48020;
		border-radius: 5px;
		border: 2px solid #f48020;
		color: #fff;
		font-size: 14px;
		// transition: 0.3s ease;
		// &:hover {
		// 	background-color: transparent;
		// 	color: #f48020;
		// }
	}
}
